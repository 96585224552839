/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';

import Bio from './bio';
import Layout from '../layout';
import SEO from '../seo';
import theme from '../../utils/themeconstants';
import Share from './share';
import Subscribe from './subscribe';

const PageBackground = styled.div`
  width: 100%;
`;

const BlogContent = styled.div`
  max-width: 672px;
  text-align: left;
  padding: 100px 1.0875rem 100px;
  margin: 0 auto;
  color: black;
  #mdcontent {
    h1 {
    font-weight: 900;
    margin-bottom: 0.5rem;
    }
    sub {
      margin-top: 12px;
      width: 100%;
      text-align: center;
      line-height: 50%;
    }
    .gatsby-resp-image-wrapper{
      margin-top: ${theme.space.lg * 1.5}px;
    }
    .figcaption{
      font-size: small;
      text-align: center;
      margin-bottom: ${theme.space.lg * 1.5}px;
    }
    blockquote {
      font-style: italic;
      font-size: 20px;
    }
    hr {
      margin: ${theme.space.lg * 2}px 0;
      text-align: center;
      height: 0;
      &:before{
        content: '...';
        display: inline-block;
        font-size: 30px;
        letter-spacing: 12px;
        margin-left: .6em;
        position: relative;
        top: -32px;
      }
    }
    a{
      color: ${theme.colors.primary};
      transition: color 0.4s;
      &:hover{
        color: ${theme.colors.red};
      }
    }
  }
  
`;

const Back = styled.div`
  margin: 0 0 1em 0;
`;

const BlogLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  color: ${theme.colors.primary};
  padding-bottom: 1px;
  transition: color 0.4s;
  text-decoration: none;
  border-bottom: 1px solid #000;
  &:hover{
    color: ${theme.colors.red};
  }
`;

const MetaDetail = styled.p`
  margin: 0px 0px 2rem;
  font-size: 0.83255rem;
  font-weight: normal;
  color: black;
`;

const FeatureImage = styled(Img)`
  width: 100%;
  margin-bottom: ${theme.space.lg * 2}px;
`;

const PageLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 2em 0;
`;

const Prev = styled.li`

`;

const Next = styled.li`

`;

const PrevNextLink = styled(Link)`
    color: ${theme.colors.primary};
    text-decoration: none;
    transition: color 0.4s;
    &:hover{
      color: ${theme.colors.red};
    }
`;

const CommentExpand = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const CommentBox = styled.div`
  overflow: hidden;
  margin-bottom: ${theme.space.lg}px;
  height: ${props => (props.commentsExpanded ? 'auto' : '0px')};
`;


// eslint-disable-next-line react/prefer-stateless-function
class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsExpanded: false,
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://commento.tmrow.com/js/commento.js');
    script.setAttribute('async', '');
    document.getElementById('commentoScript').appendChild(script);
  }

  toggleComments = () => {
    this.setState(prevState => ({
      commentsExpanded: !prevState.commentsExpanded,
    }));
  }

  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;
    const url = this.props.data.site.siteMetadata.url;

    return (
      <Layout dark blog location={this.props.location} title={siteTitle}>
        <SEO
          title={`${post.frontmatter.title} - Tomorrow Blog`}
          description={post.frontmatter.description || post.excerpt}
          previewImageURL={`${url}${post.frontmatter.image.publicURL}`}
          url={`${url}/blog/${post.fields.slug}`}
        />
        <PageBackground>
          <BlogContent>
            <Back>
              <BlogLink to="/blog">
                Tomorrow Blog
              </BlogLink>
            </Back>
            <h1>{post.frontmatter.title}</h1>
            <MetaDetail>
              {post.frontmatter.date}
              {' · '}
              {post.fields.readingTime.text}
            </MetaDetail>
            <FeatureImage fluid={post.frontmatter.image.childImageSharp.fluid} alt={post.title} />
            <div id="mdcontent" dangerouslySetInnerHTML={{ __html: post.html }} />
            <Share
              url={`${url}/blog/${post.fields.slug}`}
              title={post.frontmatter.title}
            />
            <hr
              style={{
                marginBottom: '16px',
              }}
            />
            <Bio
              author={post.frontmatter.author.id}
              position={post.frontmatter.author.bio}
              twitter={post.frontmatter.author.twitter}
              avatar={post.frontmatter.author.avatar.childImageSharp.fluid}
            />
            <CommentExpand
              type="button"
              onClick={this.toggleComments}
            >
              <h4>
                Comments
                {' '}
                <FontAwesomeIcon
                  icon={this.state.commentsExpanded ? faMinus : faPlus}
                  size="sm"
                />
              </h4>
            </CommentExpand>
            <CommentBox id="commentbox" commentsExpanded={this.state.commentsExpanded}>
              <div id="commento" />
              <div id="commentoScript" />
            </CommentBox>
            <PageLinks>
              <Prev>
                {previous && (
                  <PrevNextLink to={previous.fields.slug} rel="prev">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                    {' '}
                    {previous.frontmatter.title}
                  </PrevNextLink>
                )}
              </Prev>
              <Next>
                {next && (
                  <PrevNextLink to={next.fields.slug} rel="next">
                    {next.frontmatter.title}
                    {' '}
                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  </PrevNextLink>
                )}
              </Next>
            </PageLinks>
            <Subscribe />
          </BlogContent>
        </PageBackground>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author {
          id
          bio
          twitter
          avatar {
            childImageSharp {
              fluid(quality: 80, maxWidth: 80) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        image{
          publicURL
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
