import React from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import theme from '../../utils/themeconstants';

const BioContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const BioImage = styled(Image)`
  margin-right: 16px;
  margin-bottom: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
`;

const Bio = ({
  author,
  position,
  twitter,
  avatar,
}) => (
  <BioContainer>
    <BioImage
      fluid={avatar}
      alt={author}
      imgStyle={{ borderRadius: '50%' }}
    />

    <p>
      Written by
      {' '}
      <strong>{author}</strong>
      <br />
      {position}
      {' '}
      <br />
      follow him on
      {'  '}
      <a href={`https://twitter.com/${twitter}`}>
        <FontAwesomeIcon icon={faTwitter} color={theme.colors.primary} size="sm" />
      </a>
    </p>
  </BioContainer>
);

export default Bio;
