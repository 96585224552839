import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import Mailchimp from '../elements/mailchimp';
import theme from '../../utils/themeconstants';

const SubscribeContainer = styled.div`
  box-shadow: 0 0 12px rgba(0,0,0,0.10);
  border-radius: 8px;
`;

const Column = styled(Col)`
  padding: ${theme.space.lg}px !important;
  overflow: hidden;
  &.right {
    background-color: ${theme.colors.bgPrimary};
    border-radius: 0 8px 8px 0;
    p{
      font-size: 14px;
      margin-bottom: 0;
    }

  }
  &.left {
    background-color: ${theme.colors.primary};
    border-radius: 8px 0 0 8px;
    h3, p {
      color: white;
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 16px;
  right: 32px;
  width: 100%;
  opacity: 0.1;
`;

const Subscribe = () => (
  <SubscribeContainer>
    <Container>
      <Row>
        <Column className="left" md={6}>
          <h3>Join the Newsletter</h3>
          <p>Subscribe to get our latest content by email.</p>
          <StyledIcon icon={faPaperPlane} color="white" size="6x" />
        </Column>
        <Column className="right" md={6}>
          <Mailchimp
            color={theme.colors.primary}
            action="https://europe-west1-tmrowapp.cloudfunctions.net/mailchimp-proxy?u=8689fc68cd6c50ec671a95afb&amp;id=d11b2b98a9"
            fields={[
              {
                name: 'EMAIL',
                placeholder: 'Email',
                type: 'email',
                required: true,
              },
            ]}
            messages={{
              success: 'Thank you for joining the Newsletter!',
            }}
          />
          <p>
            We&#39;ll send you updates on Tomorrow, no more than once a month. You can unsubscribe anytime.
          </p>

        </Column>
      </Row>
    </Container>
  </SubscribeContainer>
);

export default Subscribe;
